.filter {


  &-item {
    padding: 8px 12px;
    border-radius: 30px;
    border: 1px solid $primary;
    margin-right: 10px;
    margin-bottom: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
  }

  &-itemActive {
    padding: 8px 12px;
    border-radius: 30px;
    border: 1px solid $primary;
    margin-right: 10px;
    margin-bottom: 10px;
    cursor: pointer;
    background-color: $primary;
    display: flex;
    align-items: center;
  }


  &-itemText {
    font-size: 10px;
    font-weight: $bold;
    color: $primary;
    text-transform: uppercase;
    margin-bottom: 0;
  }

  &-itemTextActive {
    font-size: 10px;
    font-weight: $bold;
    color: $white;
    text-transform: uppercase;
    margin-bottom: 0;
  }
}

.filterRefresh {
  padding: 5px 10px;
  border: 1px solid $primary;
  border-radius: 8px;
  cursor: pointer;

  &-text {
    font-size: 10px;
    text-transform: uppercase;
    font-weight: $medium;
    color: $primary;
    margin-bottom: 0;
  }
}