.progress-wrapper {
  width: 110px;
  margin-right: 50px;
}

.progress-details {
  margin-top: 10px;
}

.macro-name {
  font-size: 12px;
  font-weight: $bold;
  text-transform: uppercase;
  color: $gray;
  margin-bottom: 3px;
}

.consigliati {
  font-weight: $medium;
  font-size: 12px;
  color: $black;
}

.macros-items {
  font-size: 12px;
  font-weight: $medium;
  color: $gray;
  margin-bottom: 5px;
  margin-left: 15px;
}

.refeed-text {
  font-size: 18px;
  font-weight: $medium;
  color: $red;
  margin-left: 20px;
}